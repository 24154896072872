.logo-ribbon {
    a {
        @include hover-focus-visible {
            figure {
                img {
                    filter: grayscale(0);
                    transform: scale(1.05);
                }
            }
        }
    }

    figure {
        img {
            aspect-ratio: 21 / 9;
            filter: grayscale(1);
            transition: filter .15s linear, transform .2s ease-in-out;
        }
    }
}
